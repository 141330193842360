<template>
  <div id="tariff" class="section-plans container">
    <div class="section-plans__title-container">
      <h2 class="section-plans__title">{{ $t('startUsingDF') }}</h2>
    </div>

    <div class="section-plans__switches">

      <UiTariffsSwitch
        v-model="activeIndex"
        toggleRadius="8px"
        :toggleColor="'#839AAF'"
        containerRadius="12px"
        class="section-plans__mt"
      >
        <template v-slot:first>
          <svg :class="[{'switch__icon_white': activeIndex === 0}, {'switch__icon_dark': activeIndex === 1}]"
               width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.3575 0.664062C11.2487 0.664062 9.60654 0.984963 8.21235 1.97797C6.76662 3.00768 5.65513 4.7176 5.65513 7.30203C5.65513 9.88645 6.76662 11.5964 8.21235 12.6261C9.60654 13.6191 11.2487 13.94 12.3575 13.94C13.4664 13.94 15.1085 13.6191 16.5027 12.6261C17.9484 11.5964 19.0599 9.88645 19.0599 7.30203C19.0599 4.7176 17.9484 3.00768 16.5027 1.97797C15.1085 0.984963 13.4664 0.664062 12.3575 0.664062ZM7.66071 7.30203C7.66071 5.37588 8.44909 4.2667 9.3782 3.60495C10.3588 2.9065 11.5665 2.66358 12.3575 2.66358C13.1486 2.66358 14.3562 2.9065 15.3369 3.60495C16.266 4.2667 17.0543 5.37588 17.0543 7.30203C17.0543 9.22817 16.266 10.3374 15.3369 10.9991C14.3562 11.6976 13.1486 11.9405 12.3575 11.9405C11.5665 11.9405 10.3588 11.6976 9.3782 10.9991C8.44909 10.3374 7.66071 9.22817 7.66071 7.30203ZM12.3575 14.7596C8.02 14.7596 5.1211 16.6966 3.30654 19.1267C1.52357 21.5145 0.799008 24.353 0.670227 26.264C0.651615 26.5401 0.748678 26.8116 0.9383 27.0138C1.12792 27.216 1.39313 27.3307 1.67076 27.3307H22.3318C22.8857 27.3307 23.3346 26.8831 23.3346 26.331C23.3346 24.4209 22.8018 21.5692 21.1673 19.1631C19.4965 16.7036 16.7007 14.7596 12.3575 14.7596ZM19.5067 20.2843C20.6066 21.9034 21.1203 23.8082 21.2758 25.3312H2.8034C3.06927 23.8159 3.71271 21.9312 4.91528 20.3207C6.38987 18.3459 8.7156 16.7591 12.3575 16.7591C15.9938 16.7591 18.1852 18.3389 19.5067 20.2843Z"
            />
          </svg>
          {{ $t('home_page_forEveryone') }}
        </template>
        <template v-slot:second>
          <svg :class="[{'switch__icon_white': activeIndex === 1}, {'switch__icon_dark': activeIndex === 0}]"
               width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.0682 4C10.5159 4 10.0682 4.44772 10.0682 5V7.88315H3.66797C3.11568 7.88315 2.66797 8.33086 2.66797 8.88315V17.2966V27.0045C2.66797 27.5568 3.11568 28.0045 3.66797 28.0045H28.3354C28.8877 28.0045 29.3354 27.5568 29.3354 27.0045V17.2966V8.88315C29.3354 8.33086 28.8877 7.88315 28.3354 7.88315H21.9352V5C21.9352 4.44772 21.4875 4 20.9352 4H11.0682ZM14.1516 15.0022C13.5993 15.0022 13.1516 15.45 13.1516 16.0022V16.2966H4.66797V9.88315H11.0682H20.9352H27.3354V16.2966H18.8518V16.0022C18.8518 15.45 18.404 15.0022 17.8518 15.0022H14.1516ZM17.8518 20.2382C18.404 20.2382 18.8518 19.7905 18.8518 19.2382V18.2966H27.3354V26.0045H4.66797V18.2966H13.1516V19.2382C13.1516 19.7905 13.5993 20.2382 14.1516 20.2382H17.8518ZM19.9352 7.88315H12.0682V6H19.9352V7.88315ZM16.8518 18.2382H15.1516V17.2966V17.0022H16.8518V17.2966V18.2382Z"
            />
          </svg>
          {{ $t('home_page_forBusiness') }}
        </template>
      </UiTariffsSwitch>

      <UiTariffsSwitchWithSale
        v-model="activePrice"
        :color="'#2196F3'"
      />

    </div>


    <UiTariffsList v-if="activeIndex === 0" :style="{gridTemplateColumns: grid}">
      <UiTariffsCardOffer
        v-for="item in tariffs"
        borderRadius="24px"
        border="1px solid #1679C8"
        :timer="false"
        :priceYear="activePrice"
        :buttonColor="item.buttonColor"
        :buttonTitleColor="item.buttonTitleColor"
        :priceColor="item.priceColor"
        :key="item.tariff.id"
        :tariff="item.tariff"
        borderButton="1px solid #0081FF"
        arrowColor="#0081FF"
        buttonRadius="8px"
      />
    </UiTariffsList>

    <UiTariffsList v-else :style="{gridTemplateColumns: grid, display: display}" class="tariff-list_two">
      <UiTariffsCardOffer
        v-for="item in tariffsBusiness"
        borderRadius="24px"
        border="1px solid #1679C8"
        :priceYear="activePrice"
        :buttonColor="item.buttonColor"
        :buttonTitleColor="item.buttonTitleColor"
        :priceColor="item.priceColor"
        :key="item.tariff.id"
        :tariff="item.tariff"
        :headerColor="item.headerColor"
        arrowColor="#0081FF"
        buttonRadius="8px"
      />
    </UiTariffsList>

  </div>
</template>

<script setup lang="ts">
import {tarifPlansForOfferPage} from '~/constants';
import {useUserStore} from '~/modules/User/user.store';
import {useI18n} from 'vue-i18n';

const props = defineProps({
  grid: String,
  display: String
})

const user = useUserStore();
const {locale} = useI18n();
const router = useRouter();
const timer = ref('');
const activeIndex = ref(0);
const activePrice = ref(false);

const tariffs = [
  {
    tariff: tarifPlansForOfferPage.FREE,
    buttonColor: 'white',
    buttonTitleColor: '#0081FF',
    priceColor: '#0081FF',
  },
  {
    tariff: tarifPlansForOfferPage.STANDARD,
    buttonColor: '#0081FF',
    buttonTitleColor: 'white',
    priceColor: '#0081FF',
  },
  {
    tariff: tarifPlansForOfferPage.PREMIUM,
    buttonColor: 'white',
    buttonTitleColor: '#0081FF',
    priceColor: '#0081FF',
  },
];

const tariffsBusiness = [
  {
    tariff: tarifPlansForOfferPage.PROPERTY,
    buttonColor: '#0081FF',
    buttonTitleColor: 'white',
    headerColor: '#0081FF',
    priceColor: '#0081FF',
  },
  {
    tariff: tarifPlansForOfferPage.ENTERPRISE,
    buttonColor: '#1679C8',
    buttonTitleColor: 'white',
    headerColor: '#1679C8',
    priceColor: '#0081FF',
  },
]
</script>

<style scoped lang="scss">
.section-plans {
  height: 100%;
  width: 100%;
  margin-top: $m-25;

  &__title {
    @include title-fw-700-fs-36-black-100;
    text-align: center;
  }

  &__subtitle {
    @include subtitle-fs-14-fw-400-gray-200;
    text-align: center;
    margin-top: $mt-6;
  }
}

.section-plans__title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.section-plans__title {
  max-width: 600px;
  font-size: 48px;
}

.switch__icon_white {
  fill: white;
  transition: 0.3s;
}

.switch__icon_dark {
  fill: #3D424A;
  transition: 0.3s;
}

.price-switch {
  display: flex;
  align-items: center;
  gap: 16px;
}

.price-switch__sale {
  box-sizing: border-box;
  padding: 4px 8px;

  border: 1px solid;
  border-radius: 8px;
  border-color: black;

  //styleName: Launcher/H5;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
}

.price-switch__text {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.03em;
  text-align: center;

  color: #839AAF;
}

.price-switch__text_active {
  color: #3D424A;
}

.section-plans__mb {
  margin-bottom: 48px;
}

.section-plans__switches {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.tariff-list_two {
  display: flex;
  justify-content: center;
}
</style>
